.padded-section {
	padding: 50px 0;

	img { max-width: 100%; }
}

.intro-section {
	@include secondary-type(18px);
	text-align: center;
	color: $text-primary;
	font-weight: 600;
}

.intro-section.slide-in-bottom.hide_in_mobile{
@media (max-width: $screen-sm){
		display: none;
}
}

.spacer_certifications:first-child img{
	@media(max-width: $screen-sm){
		padding-top: 45px;
	}
}

.dark-back1 {
	background: $dark-back1;
}

.dark-back2 {
	background: $dark-back2;

	h2 { color: $white; }

	.vision-list {
		width: auto;
		margin: 0 auto;
		position: relative;
		overflow: auto;
		padding: 0 15px;

		@media (min-width: $screen-md) {
			padding: 0;
		}

		.vl-item {
			@include secondary-type(20px);
			float: left;
			color: $white;
			padding-left: 30px;
			background: url('../images/yellow-x.png') no-repeat left 10px;

			@media (min-width: $screen-md) {
				@include secondary-type(24px);
				width: 50%;
			}
		}
	}

}

.rd-list {
	ul {
		display: block;
		margin-right: 15px;
		float: left;
		margin: 0;
		padding: 0;
		list-style: none;
		width: 50%;
		margin-top:30px;

		& > li {
			background: url('../images/black-x.png') no-repeat left 5px;
			text-align: left;
			padding-left: 20px;
		}
	}
}

.contact-section-closed {
	background: $brand-primary;
	padding-left: 15px;
	padding-right: 15px;
	overflow: hidden;

	//@media (min-width: $screen-sm) {
	    text-align: center;
	//}
}

.contact-section-open {
	background: $contact-back;
	text-align: center;

	h3 {
		@include primary-type(20px);
		font-weight: 900;
		color: $titles-primary;
		padding-bottom: 15px;
		text-align: center;
		text-transform: uppercase;
		margin-bottom: 40px;
		background: url('../images/titles-under-dark.png') no-repeat center bottom;
	}
}

.product-content {
	h1 {
		@include primary-type(42px);
		font-weight: 900;
		color: $titles-primary;
		margin-bottom: 35px;
		background: none;
		text-align: left;

		@media (min-width: $screen-sm) {
		    @include primary-type(54px);
		}

		@media (min-width: $screen-md) {
		    @include primary-type(60px);
		}
	}

	h2 {
		@include secondary-type(20px);
		margin: 10px 0;
		font-weight: 600;
		padding: 0 0 0 30px;
		background: url('../images/black-x.png') no-repeat left center;
		text-align: left;

		@media (min-width: $screen-md) {
		    margin: 0;
		}
	}

	.product-gallery-item {
		overflow: hidden;
	}

	.product-gallery {
		min-height: 400px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		margin: 0 -15px;
		-moz-transition: all .5s;
	    -webkit-transition: all .5s;
	    transition: all .5s;
	    -moz-transform: scale(1,1);
	    -webkit-transform: scale(1,1);
	    transform: scale(1,1);

		&:hover {
		    -moz-transform: scale(1.3,1.3);
		    -webkit-transform: scale(1.3,1.3);
		    transform: scale(1.3,1.3);
		}
	}

	.product-container {
		background-repeat: no-repeat;
		background-size: 250px 250px;

		@media (min-width: $screen-md) {
		    background-size: 320px 320px;
		}
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			padding-left: 20px;
			background: url('../images/yellow-x.png') no-repeat left 5px;
		}
	}

	.product-gallery-text {
		position: relative;
		height: 100%;
		background: $product-box-back;
		margin: 0 -15px;

		.pgt-content {

			padding: 40px 15px;

			@media (min-width: $screen-md) {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				padding: 0 25px;
			}

			p {
				@include secondary-type(16px);
				color: $white;
			}

			li {
				color: $white;
			}
		}

		h3 {
			@include secondary-type(16px);
			color: $white;
			background: url('../images/white-x.png') no-repeat left center;
			font-weight: 600;
			text-transform: uppercase;
			padding-left: 25px;
		}
	}

	.applications-section {
		background: $app-section-back;
		text-align: center;
		margin: 0 -15px;
		padding-left: 15px;
		padding-right: 15px;

		@media (min-width: $screen-md) {
			padding-left: 0;
			padding-right: 0;
		}

		h3 {
			@include primary-type(20px);
			font-weight: 900;
			color: $white;
			padding-bottom: 15px;
		    text-align: center;
		    text-transform: uppercase;
			margin-bottom: 40px;
			background: url('../images/titles-under-dark.png') no-repeat center bottom;
		}

		ul {
			@media (min-width: $screen-md) {
				display: block;
			    float: left;
			    width: 33%;
			}

			li {
				background: url('../images/white-x.png') no-repeat left 8px;

			}

			li, li a {
				@include secondary-type(18px);
				color: $titles-primary;
				text-align: left;

				@media (min-width: $screen-md) {
					@include secondary-type(22px);
				}
			}
		}
	}
}

.exes-list {
	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			padding-left: 25px;
			background: url('../images/yellow-x.png') no-repeat left 5px;
			margin-bottom: 10px;
		}
	}
}

.line-before {
	&:before {
		content: "";
		display: block;
		width: 55px;
		height: 5px;
		background: $brand-primary;
		margin-bottom: 20px;
	}
}

.margin-top-25 {
	margin-top: 25px;
}

.margin-bottom-50 {
	margin-bottom: 50px;
}

.vm-image {
	text-align: center;
	margin-bottom: 70px;
}

.back-image {
	background-position: center center;
	background-size: cover;
	min-height: 400px;
	background-repeat: no-repeat;

	&.bi-rd {
		h2 { margin-top: 50px; position: absolute; width: 100%;}
		min-height: 1px;
		img { width: 100%; height: auto; }
	}
}

.pac-content a.dark-button {
    margin-left: 0px;
}

.focus-mobile p {
	@include primary-type(20px);
	color: $brand-primary;
	padding: 0 15px;
	text-transform: uppercase;

	strong {
		@include primary-type(28px);
	}
}
