$i-phone: 320px;
//screen-xs: 480px
//screen-sm: 768px
//screen-md: 992px

/*nav div ul#menu-mesgo-main li:last-child {
	@media(max-width: 700px){
    display: none;
		color: #fff;
	}
}*/

.panel-body ul li a {
    color: #fff;
}

.navbar-header {
		@media(max-width: $screen-md){
    text-align: center;
	}
}

#lang_sel{
	border-radius: 5px;
	border: 1px solid #fff;
	}

#lang_sel > ul > li > a{
	background-color: rgba(255, 255, 255, 0) !important;
	color: #fff !important;
}
#lang_sel a, #lang_sel a:visited{
	border-color: rgba(205, 205, 205, 0) !important;
	text-align: left;
}

.navbar-brand span.masco-logo{
		@media(max-width: $screen-xs){
		background: url(../../../../uploads/2016/06/mesgo-logo-mobile.png) no-repeat center center;
		width: 174px;
    height: 56px;
	}
}

#lang_sel{
			margin-top: 9px;
			margin-bottom: 8px;
			padding-top: 2px;
}
#lang_sel li{
	width: 45px !important;
}
#lang_sel ul ul{
	width: 45px !important;
}
span.icl_lang_sel_current.icl_lang_sel_native {
    font-size: 14px;
}


.dark-back2.bi-app h2{
	@media(max-width: $screen-sm){
					max-width: none !important;
	}
}

.only-mobile-menu//menu
{
 @media (min-width: $screen-sm) {
     display: none !important;
 }}

.col-xs-12 .products-animation .product-animation-container .pac-content//prodotti
{
	@media (max-width : $screen-sm){
			position: relative;
			float: right;
			right: 8%;
			width: 50%;
			top: 50%;
		  transform: translateY(-50%);
	}}

.navbar-collapse div.menu-mesgo-main-container {//menu
	@media (max-width : $screen-md){
	float: none;
}}

.col-xs-12 .products-animation .selector-numbers{//prodotti
	@media (max-width : $screen-sm){
	left: 28%;
	transform: translateY(-50%);
	top: 50%;
}}

	.col-xs-12 .products-animation .product-animation-container .pac-content h3{//prodotti
		@media (max-width : $screen-sm){
			margin-top: 0px;
			font-size: 26px;
		}}

.col-xs-12 .products-animation .product-animation-container{//prodotti
	@media (max-width : $screen-sm){
		height: 100%;
    position: absolute;
    width: 100%;
	}}

.col-xs-12 .products-animation .product-animation-container .pac-rounded-back {//prodotti
	@media (max-width : $screen-sm){
	background-position: center top;
	background-size: contain;
}}

	.container .row .col-xs-12 .products-animation .selector-bottom {//prodotti
		@media (max-width : $screen-sm){
			  display: none;
		}	}

	.products-animation div .pac-image img {//prodotti
		@media (max-width : $screen-sm){
				  display: none;
		}	}

		.menu-mesgo-main-container .navbar-nav .open .dropdown-menu>li>a{//menu
				@media (max-width : $screen-sm){
						  line-height: 13.5px;
				} }

	header.navbar-default ul.navbar-nav>li ul.dropdown-menu li>a{
			@media (max-width : $screen-sm){
				font-size: 16px;
				font-weight: 600;
			}}

		header.navbar-default ul.navbar-nav>li ul.dropdown-menu li{
				@media (max-width : $screen-sm){
						background: rgba(0,0,0,0);
				}}

		body .mobile_menu{
				@media (max-width : $screen-sm){
					position: fixed;
					background: rgba(51,51,51,.9);
				}}

		.main .row.slide-in-bottom.dark-back2.back-image.bi-rd{
				@media (max-width : $screen-sm){
					display: none;
				}}

			#myAffix .header-menu-container{
					@media (max-width : $screen-sm){
					  padding: 16px 21px 0px 21px;
			}}

			div#slide-1-layer-5{
					@media (max-width : $screen-sm){
								padding-top: 110px !important;
					}}

				.col-xs-12 .products-animation{
					@media (max-width : $screen-sm){
					       margin-bottom: 35px;
					}			}

				body .navbar-default .navbar-toggle{
						@media (max-width : $screen-sm){
								border-color: $white;
						}		}

				body .navbar-default .navbar-toggle .icon-bar{
							@media (max-width : $screen-sm){
									background-color: $white;
							}	}

			body .navbar-default .navbar-toggle:hover {
				@media (max-width : $screen-sm){
					background-color: rgba(0,0,0,0);
				}}


        @media (min-width: $screen-xs) and (max-width : $screen-md){
        	a.product5 {
        		clear: both;
        	}
        	.padded-section img {
            padding-bottom: 71px;
        	}
        	.col-xs-12 .products-animation .product-animation-container .pac-content p {
        		display: none;
        	}
        	.tp-mask-wrap .tp-caption img {
        			width: 457.9px !important;
            	height: 182.7px !important;
        	}

        	.col-xs-12 .products-animation .product-animation-container .pac-content p {
        			font-size: 14.5px;
        	}
        	.pac-content a.dark-button {
        	    margin-left: 0px;
        	}
        }

        @media (max-width: $screen-xs) {
        	.pac-content a.dark-button {
        	  margin-left: 0px;
        		padding: 15px 5px;
        	}
        	.col-xs-12 .products-animation .product-animation-container .pac-content p {
        		display: none;
        	}
        	.pac-content .dark-button:after{
        			border: 1px solid #333;
        			width: 30px;
        			right: -29px;
        	}
        	.pb-link > a.yellow-button-full {
            width: 242px;
        }
        	.col-sm-4 > .plant-badge > .pb-title {
        		width: 288px;
        	}
        }
