.category-selector, .year-selector {
	text-align: center;
	font-size: 0;
	margin-bottom: 20px;
	margin-top: 50px;

	a {
		@include primary-type(13px);
		display: inline-block;
		color: $text-primary;
		text-decoration: none;
		padding: 5px 10px;
		background: $white;
		border: 1px solid $brand-primary;

		&:hover, &.current {
			background: $brand-primary;
		}
	}
}

.year-selector {
	display: none;
	margin-top: 20px !important;
	 
	&:before {
		display: none;
	}
}

.year-month-menu {
	position: relative;

	.months {
		left: 0;
		position: absolute;
		text-align: left;
		top: 10px;
		z-index: 99;

		a {
			display: block;
    		text-align: center;
		}
	}
}
