.news-pagination {
	text-align: center;

	span, a {
		@include primary-type(15px);
		padding: 10px 25px;
		border: 1px solid $dark-btn-border;
		text-decoration: none !important;
		color: #333;

		background: linear-gradient(to right, #333 50%, transparent 50%);
		background-size: 200% 100%;
		background-position: right bottom;
		transition: all .5s ease !important;

		&:hover {
			background-position: left bottom;
			color: $white !important;
		}

		&.current {
			border: 1px solid $brand-primary;
			color: $brand-primary;
			background: linear-gradient(to right, $brand-primary 50%, transparent 50%);
			background-size: 200% 100%;
			background-position: right bottom;
			transition: all .5s ease !important;

			&:hover {
				background-position: left bottom;
				color: $white !important;
			}
		}
	}

}
