.video-badge {
    margin-bottom: 60px;
    
    .vb-image {
        position: relative; 
        margin-bottom: 30px;

        img {
            width: 100%;
            height: auto;
        }

        .vb-play {
            display: block;
            width: 65px;
            height: 65px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: url('../images/mesgo-play.png') no-repeat center center; 
            background-size: cover;
        }
    }

	.vb-date {
		@include secondary-type(18px);
		color: $text-primary;
		text-transform: uppercase;
		font-weight: 600;

		span {
			color: $brand-primary;
		}
	}

	h3 {
		margin-bottom: 10px;
	    margin-top: 10px;
	    

		a {
			@include primary-type(24px);
			color: $titles-primary !important;
			font-weight: 900;
			text-decoration: none !important;
		}
    }
    
    .vb-description {
        p:first-child {
          strong {
            font-size: 18px;
            color: #000;
            line-height: 120%;
          }
        }
        
        p:last-child {
            color: #000;
            font-size: 14px;
            padding-bottom: 30px;
            background: url('../images/titles-under.png') no-repeat left bottom; 
        }
    }
}
