.footer-content {
	background: $footer-back;
	padding-right: 60px;
	padding-left: 60px;

	a {
		text-decoration: none !important;
		color: inherit !important;
	}

	@media (max-width: $screen-sm){
			padding-right: 10px;
			padding-left: 10px;
		}

	.footer-txt{
		@include primary-type(16px);
		color: $white;
		padding-top: 25px;
	}

	.footer-logo {
		background: url('../images/mesgo-logo-footer.png') no-repeat left center;
		background-size: contain;
		min-height: 64px;
	}

	.linkedin {
		margin-top: 20px;
		display: block;
		float: right;
		width: 27px;
		height: 27px;
		background: url('../images/linkedin.png') no-repeat center center;

		@media(max-width: $screen-sm){
			float: left;
		}
	}
}
