body{
    overflow-x: hidden;
}
.prodotto-mesgo-tit-faschione{
    padding-top: 50px;
    position: relative;
    width: 100vw;
    left: -15px;
    background-color: $brand-primary;
    color: $product-box-back;

    h1{
        font-size: 40px;
    }
}
.prodotto-mesgo-flexible{
    position: relative;
    width: 100vw;
    left: -15px;

    ul{
        list-style-type: none;
        list-style-position: inside; 
        margin-left: 10px;

        li{
            position: relative;
            background: none !important;
            padding-left: 7px;

            &::before{
                content:"•";
                position: absolute;
                top: -5px;
                left: -10px;
                font-size: 25px;
            }

            &::marker {
                display: none;
            }
        }
    }

    section{
        &.tit-txt-btn{
            padding-top: 80px;
            padding-bottom: 80px;

            .testo{
                padding-top: 20px;
                padding-bottom: 20px;

                @media (max-width: $screen-sm) {
                    padding-top: 0px;
                    padding-bottom: 0px;
                }
            }

            .tasti{
                a{
                    padding: 5px 10px;
                    display: inline-block;
                    white-space: nowrap;
                    text-decoration: none;
                }
            }

            &.neutro{
                .testo{
                    p{
                        color: $product-box-back !important;
                    }
                }

                .tasti{
                    a{
                        background-color: $brand-primary;
                        color: $product-box-back;

                        &:hover{
                            background-color: $product-box-back;
                            color: $brand-primary;
                        }
                    }
                }
            }

            &.positivo{
                background-color: $brand-primary;

                .testo{
                    p{
                        color: $product-box-back !important;
                    }
                }
                
                .tasti{
                    a{
                        background-color: $product-box-back;
                        color: $brand-primary;

                        &:hover{
                            background-color: $product-box-back;
                            color: #fff;
                        }
                    }
                }
            }

            &.negativo{
                background-color: $product-box-back;
                color: #fff;

                .testo{
                    p{
                        color: #fff !important;
                    }
                }
                
                .tasti{
                    a{
                        background-color: $brand-primary;
                        color: $product-box-back;

                        &:hover{
                            background-color: #fff;
                            color: $product-box-back;
                        }
                    }
                }
            }
        }

        &.colonne{
            &.desktop{
                .col{
                    padding-top: 25vw;

                    .content{
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        // background-color: $brand-primary;
                        background-color: #eae9e9;

                        .testo{
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            padding: 40px;

                            p{
                                color: $product-box-back;
                            }
                        }

                        img{
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }
                }

                @media (max-width: $screen-lg) {
                    display: none;
                }
            }

            &.mobile{
                .col{
                    padding-top: 25vw;

                    .content{
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        background-color: $brand-primary;

                        .testo{
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            padding: 40px;

                            p{
                                color: $product-box-back;
                            }
                        }

                        img{
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }
                }
                
                @media (min-width: $screen-lg) {
                    display: none;
                }
            }
        }

        &.confezioni{
            background-color: rgba(0,0,0,0.03);

            .rowN{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }

            .colN{
                position: relative;
                float: none;
                display: flex;
            }

            .cardConfezione{
                width: calc(100% - 20px);
                background-color: #fff;
                margin-bottom: 40px;
                text-align: center;

                img{
                    display: inline-block;
                    max-width: 65%;
                }

                .fasciaTit{                    
                    padding: 15px 20px;
                    background-color: rgba(244,200,46,1);
                    margin-bottom: 20px;
                }

                .title{
                    text-align: left;
                    font-size: 20px;
                    font-weight: bold;
                }

                .subtitle{
                    text-align: left;
                    font-size: 12px;
                    text-transform: uppercase;
                }
            }

            table{
                padding: 20px;
                text-align: left;
                margin-left: 20px;

                td{
                    padding-top: 5px;
                    padding-bottom: 5px;
                    padding-left: 15px;
                    padding-right: 15px;
                    border-bottom: 1px solid rgba(0,0,0,0.1);
                    font-size: 12px;
                }
            }
            .dida{
                padding: 20px;
                text-align: left;
            }
            .dida p, .dida p em{
                font-size: 12px;
            }

            h3{
                margin-top: 100px;
                margin-bottom: 60px;
            }
        }
    }
}