.generic-form-container {
	border-top: 1px solid $border-primary;
	margin-top: 60px;
}

.wpcf7-not-valid-tip { display: none !important; }
input[aria-invalid="true"] { border-color: #ff0000 !important; }

.contact-form {
	padding: 15px 30px 60px;
	position: relative;
	color: #333;

	input, select {
		height: 45px;
		width: 100%;
		margin-top: 15px;
		border: 1px solid $border-primary;
		padding: 0 10px;
	}

	input::placeholder, textarea::placeholder {
		@include primary-type(16px);
		text-transform: uppercase;
		color: $titles-primary;
		font-weight: 900;
	}

	input, textarea, select {
		@include primary-type(16px);
		color: $titles-primary;
		font-weight: 900;
		background: transparent;
	}


	.file {
		padding-top: 15px;

		.form-label {
			display: inline-block;
		}
		input {
			border: 0;
			display: inline-block;
		}
	}

	.two-items {
		margin: 0 -15px;
		span {

			padding: 0 15px;
			display: inline-block;

			@media (min-width: $screen-md) {
				width: 50%;
			}
		}
	}

	textarea {
		width: 100%;
		height: 150px;
		border: 1px solid $border-primary;
		padding: 10px;
		margin-top: 15px;
		resize: none;
	}

	input[type="submit"] {
		@include generic-button();
		color: $titles-primary !important;
		border: 1px solid $titles-primary;

		background: linear-gradient(to right, $titles-primary 50%, transparent 50%);
	    background-size: 200% 100%;
	    background-position: right bottom;
	    transition: all .5s ease;

		&:hover {
			background-position: left bottom;
			color: $white !important;
		}

		&:after {
			content: '';
			border: 1px solid $titles-primary;
			height: 45px;
			width: 45px;
			background: url('../images/dark-arrow.png') no-repeat center center;
			position: absolute;
		    right: -48px;
		    top: -1px;
		}
	}

	.wpcf7-not-valid-tip {
		display: block;
		margin-top: 0 0 15px 0;
	}

	h4 {
		color: #09c7e6;
		font-weight: 300;
		padding-bottom: 10px;
		border-bottom: 1px solid #09c7e6;
	}

	.form-vertical-space {
		margin-top: 65px;
	}

}
