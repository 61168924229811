// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #fccb25;
$white:					#fff;

$titles-primary:		#333;
$text-primary:			#666;

$dark-back1:			#f2f2f2;
$dark-back2:			#333333;

$dark-btn-border:		#76662e;

$footer-back:			#333333;

$product-box-back:		#333333;
$app-section-back:		#fa4d3c;
$contact-back:			#f2f2f2;

$border-primary:		#494949;

$post-body-back:		#f2f2f2;

$contacts-dark:			#333333;
$contacts-light:		#f2f2f2;

$history-back:			#333333;

$menu-border:			#666666;

$accordion-dark:		#333333;
$accordion-light:		#666666;
$accordion-border:		#8e8e8e;
