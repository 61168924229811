.map-selector-container {
	position: relative;
}

.location-selector {
	background: $white;
	z-index: 95;
	border: 1px solid $border-primary;
	top: 20px;
	left: 20px;

	@media ( min-width: $screen-sm-min ) {
		position: absolute;
		width: 300px;
	}

	.location-search {
		padding: 20px;

		input, select {
			width: 100%;
			margin-bottom: 10px;
			height: 30px;
			color: $text-primary;
		}

		a {
			@include primary-type(12px);
			display: block;
			text-align: right;
		}
	}

	.location-items {
		max-height: 240px;
		overflow-y: scroll;
		border-top: 1px solid $border-primary;

		@media ( min-width: $screen-sm-min ) {
			display: none;
		}


		.ls-item {
			padding: 5px 20px;
			cursor: pointer;
			background: url('../images/freccia_stilmas_world.png') no-repeat right 10px top 15px;

			&.open-item {
				background: url('../images/freccia_stilmas_world_inv.png') no-repeat right 10px top 15px;
			}

			@media ( min-width: $screen-sm-min ) {
				background-image: none;
			}

			&:nth-child(odd) {
				//background-color: $map-secondary;
			}

			&.corporate {
				h6 {
					color: $brand-primary !important;
				}
			}

			.ls-item-content {

				h6 {
					@include primary-type(15px);
					color: $text-primary;
					margin-bottom: 0;
					text-transform: uppercase;
					padding-right: 15px;
				}

				.ls-item-country {
					@include primary-type(12px);
					//color: $brand-tertiary;
				}

				.ls-item-contacts {
					@include primary-type(12px);
					display: none;

					strong {
						//color: $brand-tertiary;
					}
				}
			}
		}
	}
}
