.products-animation {
	position: relative;
	margin-bottom: 50px;
	height: 510px;

	.selector-numbers {
		width: 20px;
		display: inline-block;
		position: absolute;
		left: 50%;
		top: 45px;
		margin-left: -7px;
		z-index: 9;

		a {
			@include secondary-type(20px);
			color: $titles-primary;
			display: block;
			text-decoration: none;
			cursor: pointer;
			margin-bottom: 10px;

			&.active, &:hover {
				color: $white;
			}
		}
	}

	.selector-bottom {
		position: absolute;
		bottom: -10px;
		text-align: center;

		z-index: 9;
		width: 100%;

		.selectors { padding: 10px; display: inline-block; background: $white; }

		a {
			@include primary-type(13px);
			color: $titles-primary;
			text-transform: uppercase;
			display: block;
			padding: 5px 10px;
			border: 1px solid $titles-primary;
			font-weight: 700;
			text-decoration: none !important;
			cursor: pointer;
			float: left;

			&.product6 { padding: 14px 10px 15px; }

			&.active, &:hover {
				color: $white;
				background: $titles-primary;
			}
		}
	}

	.product-animation-container {
		height: 510px;
		position: absolute;
		width: 100%;
		overflow: hidden;

		.pac-rounded-back {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-position: center bottom;
			background-repeat: no-repeat;
			z-index: 1;
			bottom: -510px;
			opacity: 0;
		}

		.pac-image {
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: 2;
			display: none;
		}

		.pac-content {
			z-index: 2;
			width: 42%;
			position: absolute;
			right: 0;
			top: 0;
			display: none;

			h3 {
				@include primary-type(56px);
				color: $titles-primary;
				font-weight: 900;
				margin-top: 40px;
				line-height: 90%;

				&.t-eng br { display: none; }
			}

			p {
				@include secondary-type(18px);
				color: $titles-primary;
				font-weight: 400;
				line-height: 120%;
			}
		}
	}
}
