.post-content {
	.post-head {
		margin-top: 40px;

		.ph-date {
			@include secondary-type(18px);
			color: $text-primary;
			text-transform: uppercase;
			font-weight: 600;
			text-align: center;
			margin-bottom: 20px;

			span {
				color: $brand-primary;
			}
		}

		.post-image {
			margin: 0 -15px;
			background-position: center center;
			background-size: cover;
			min-height: 420px;
			height: 100%;
		}

		.post-body {
			//background: url('../images/yellow-x.png') no-repeat 40px 80px $post-body-back;
			background: $post-body-back;
			padding: 110px 40px;
			height: 100%;
			margin: 0 -15px;

			.pb-content {
				max-width: 500px;

				h1 { font-size: 40px; }
			}
		}
	}
}
