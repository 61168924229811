.news-badge {
	margin-bottom: 60px;

	.nb-date {
		@include secondary-type(18px);
		color: $text-primary;
		text-transform: uppercase;
		font-weight: 600;

		span {
			color: $brand-primary;
		}
	}

	h3 {
		margin-bottom: 30px;
	    margin-top: 0;
	    padding-bottom: 30px;
		background: url('../images/titles-under.png') no-repeat left bottom;

		a {
			@include primary-type(36px);
			color: $titles-primary !important;
			font-weight: 900;
			text-decoration: none !important;
		}
	}
}

.work-badge {
	h3 {
		@include primary-type(36px);
		margin-bottom: 30px;
	    margin-top: 0;
	    padding-bottom: 30px;
		background: url('../images/titles-under.png') no-repeat left bottom;
		color: $titles-primary !important;
		font-weight: 900;
		text-decoration: none !important;
	}

	.work-content {
		margin-bottom: 30px;
	}

	&.work-badge-generic {
		border: 1px solid $brand-primary;
		padding: 30px;
	}
}
