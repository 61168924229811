.about-item {
	height: auto;
	margin: 0 -15px;

	@media (min-width: $screen-md) {
	    min-height: 400px;
		height: 100%;
	}

	& > div {
		height: 100%;
		width: 100%;
	}

	.ai-image {
		background-position: center center;
		background-size: cover;
		position: relative;
		height: 200px;

		@media (min-width: $screen-md) {
		    min-height: 400px;
			height: 100%;
		}

		h4 {
			@include primary-type(16px);
			border: 1px solid $white;
			color: $white;
			display: block;
			max-width: 40%;
			margin: 0 auto;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			padding: 10px;
			text-align: center;
		}
	}

	.ai-content {
		@include secondary-type(16px);
		text-align: center;
		background: url('../images/yellow-x.png') no-repeat center 60px $history-back;
		padding: 110px 15px 20px;

		@media (min-width: $screen-md) {
		    padding: 110px 60px 40px;
		}

		&.ai-c-light {
			background-color: $dark-back1;

			p {
				color: $titles-primary !important;
			}
		}

		p {
			color: $white !important;
		}
	}
}
