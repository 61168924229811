.history-item {
	min-height: 375px;
	margin: 0 -15px;

	@media (min-width: $screen-md) {
		min-height: 445px;
	}

	& > div {
		height: 100%;
		width: 100%;
	}

	.hi-front {
		background-position: center center;
		background-size: cover;
		position: relative;

		h4 {
			@include primary-type(16px);
			border: 1px solid $white;
			color: $white;
			display: block;
			max-width: 33%;
			margin: 0 auto;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			padding: 10px;
			text-align: center;
		}
	}

	.hi-back {
		@include secondary-type(16px);
		text-align: center;
		background: url('../images/yellow-x.png') no-repeat center 90px $history-back;
		padding: 140px 15px 20px;

		@media (min-width: $screen-md) {
			padding: 140px 60px 0;
		}

		p {
			color: $white !important;
		}
	}
}
