/* Types */
@mixin lato {
	font-family: 'Lato', sans-serif;
}

@mixin dosis {
	font-family: 'Dosis', serif;
}


/* Rename types to have generic names */
@mixin primary-type($size) {
	@include lato;
	font-size: $size;
}

@mixin secondary-type($size) {
	@include dosis;
	font-size: $size;
}

@mixin tertiary-type($size) {

	font-size: $size;
}
