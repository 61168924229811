#map-container {
	.contacts-map {
		height: 500px;
	}
}

.stilmas-world { position: relative; margin-top:60px; }

#map-container {
	z-index: 1;
	position: relative;
}

#map-canvas {
	width: 100%;
	position: inherit !important;
	height: 300px;

	&.stilmashq {
		height: 450px;
	}

	&.stilmasworld {
		height: 750px;
		margin-top: 1px solid $border-primary;
		display: none;

		@media ( min-width: $screen-sm-min ) {
			display: block;
		}
	}
}

.almainfobox {
	@include primary-type(14px);
	/*width: 400px;
	height: 260px;*/
	color: $brand-primary;
	background-color: $white;
	background-position: right center;
    background-repeat: no-repeat;

	.infobox-content {
		padding: 30px;
		background-position: 10px 35px;
		background-repeat: no-repeat;

		.title {
			@include primary-type(22px);
			line-height: 100%;
			margin-bottom: 5px;
			font-weight: 600;
			text-transform: uppercase;
		}

		a {
			text-decoration: underline;
		}

		.infowindow-address, .infowindow-cap-city {
			font-size: 13px;
			font-style: italic;
			font-weight: 300;
			margin: 0 3px;

			span {
				font-style: normal;
				margin: 0 3px;
			}
		}

		.infowindow-web {
			font-size: 13px;

			a {
				font-weight: 600;
				color: $white;
				text-decoration: none;
			}

			a:hover {
				text-decoration: none;
			}
		}

		.contact {
			font-size: 13px;
			margin-top: 20px;
			display: none;
		}
	}

	h1 {
		color: $brand-primary;
	}
}
