.exes-animation {
	height: 220px;
	width: 564px;
	position: relative;

	.ea-dot {
		position: absolute;
		width: 28px;
		height: 28px;
	}

	#reddot { left: 53px; top: 59px; }
	#orangedot { left: 62px; top: 148px; }
	#purpledot { left: 168px; top: 59px; }
	#greendot { left: 74px; top: 148px; }
	#bluedot { left: 132px; top: 102px; }
	#pinkdot { left: 237px; top: 3px; }

	.ea-popup {
		@include primary-type(16px);
		font-weight: 900;
		text-align: center;
		padding: 15px 20px;
		display: none;
		position: absolute;
		width: 240px;
		background: rgba(0, 0, 0, 0.8);
		color: $white;

		&:after {
			position: absolute;
			content: "";
			background: url('../images/black-triangle.png') no-repeat center center;
			width: 29px;
			height: 19px;
			bottom: -19px;
			left: 105px;
		}
	}

	#redpopup { left: -57px; top: -17px; }
	#orangepopup {  left: -48px; top: 71px; }
	#purplepopup { left: 59px; top: -19px; }
	#greenpopup { left: -29px; top: 70px; }
	#bluepopup { left: 26px; top: 24px; }
	#pinkpopup {
		left: 71px;
    	top: -76px;

		&:after {
			left: 167px;;
		}
	}

	.ea-step1 {
		height: 220px;
		width: 564px;
		position: absolute;
		top: 0; left: 0;

		div { opacity: 0; }

		.ea-sector1 {
			background: url('../images/x1.png') no-repeat center center;
			width: 97px;
			height: 100%;
			position: absolute;
			top: 220px; left: 0;
		}

		.ea-sector2 {
			background: url('../images/x2.png') no-repeat center center;
			width: 197px;
			height: 100%;
			position: absolute;
			top: 220px; left: 98px;
		}

		.ea-sector3 {
			background: url('../images/x3.png') no-repeat center center;
			width: 270px;
			height: 100%;
			position: absolute;
			top: 220px; left: 294px;
		}
	}

	.ea-step2 {
		height: 220px;
		width: 564px;
		position: absolute;
		top: 0; left: 0;

		div { opacity: 0; position: absolute; top: 0;}

		.ea-sector1 {
			background: url('../images/x1dot.png') no-repeat center center;
			width: 97px;
			height: 100%;
			left: 0;
		}

		.ea-sector2 {
			background: url('../images/x2dot.png') no-repeat center center;
			width: 197px;
			height: 100%;
			left: 98px;
		}

		.ea-sector3 {
			background: url('../images/x3dot.png') no-repeat center center;
			width: 270px;
			height: 100%;
			left: 294px;
		}
	}
}
