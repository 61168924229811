.animation-container {
	overflow: hidden;
}

.slide-in-bottom {
	position: relative;
	top: 25px;
	opacity: 0;
}

.slide-in-right {
	position: relative;
	left: 25px;
	opacity: 0;
}

.slide-in-left {
	position: relative;
	left: -25px;
	opacity: 0;
}
