.dark-back2.bi-app {
	background: $dark-back2;
	position: relative;
	height: 420px;

	h2 {
		@include primary-type(36px);
		border: 1px solid $white;
		background: none !important;
		color: $white;
		display: block;
		max-width: none;
		margin: 0 10px;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		padding: 10px;
		text-align: center;

		@media (min-width: $screen-md) {
		    margin: 0 auto;

			max-width: 33%;
		}
	}
}

.application-item {
	height: 420px;
	margin: 0 -15px;
	position: relative;

	& > div {
		height: 100%;
		width: 100%;
	}

	.ap-front {
		@include secondary-type(16px);
		text-align: center;
		background: url('../images/yellow-x.png') no-repeat center 60px $history-back;
		padding: 110px 20px 40px;
		position: absolute;
		width: 100%;
		height: 100%;

		@media (min-width: $screen-md) {
		    padding: 110px 60px 40px;
		}

		&.ap-f-light {
			background-color: $dark-back1;

			p {
				color: $titles-primary !important;
			}
		}

		p {
			@include secondary-type(32px);
			color: $white !important;
			text-transform: uppercase;

			@media (min-width: $screen-md) {
			    @include secondary-type(40px);
			}
		}

		img { margin-top: 30px; cursor: pointer; }
	}

	.ap-back {
		.ap-accordion-container {
			max-width: 100%;
			background: $accordion-light;
			height: 100%;
			border: 1px solid $accordion-dark;

			.panel-group {
				margin: 0 !important;
			}

			.panel {
				border-left: 0 !important;
				border-right: 0 !important;
				border-top: 0 !important;
				border-radius: 0 !important;
				box-shadow: none !important;
				margin-top: 0 !important;
				border-bottom: 1px solid $accordion-border;
			}

			.panel-body {
				padding: 20px 40px;
				background: $accordion-light;
				border: 0 !important;

				ul {
					margin: 0;
					padding: 0;
					list-style: none;

					li {
						padding-left: 30px;
						background: url('../images/yellow-x.png') no-repeat left 5px;
						color: $white;
						margin-bottom: 5px;
					}
				}
			}

			.panel-heading {
				@include secondary-type(16px);
				background: $accordion-dark;
				color: $white;
				padding: 10px 30px;
				text-transform: uppercase;
				border: !important;
				border-radius: 0 !important;

				a {
					text-decoration: none !important;
					width: 100%;
					padding: 2px 0;
					display: inline-block;
					background: url('../images/close-minus.png') no-repeat right center;

					&.collapsed {
						background: url('../images/collapsed-plus.png') no-repeat right center;
					}

					&.no-plus { background: transparent !important; }
				}
			}
		}
	}
}
