@mixin generic-button {
	@include primary-type(10px);
	font-weight: 900;
	text-transform: uppercase;
	display: inline-block;
	height: 45px;
	position: relative;
	text-decoration: none !important;
	padding: 15px 25px;

	@media (min-width: $screen-sm) {
	    @include primary-type(13px);
		padding: 10px 25px;
	}
}

.yellow-button {
	@include generic-button();
	color: $brand-primary !important;
	border: 1px solid $brand-primary;
	background: linear-gradient(to right, $brand-primary 50%, transparent 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease !important;

	&:hover {
		background-position: left bottom;
		color: $white !important;
	}

	&:after {
		content: '';
		border: 1px solid $brand-primary;
		height: 45px;
		width: 45px;
		background: url('../images/yellow-arrow.png') no-repeat center center;
		position: absolute;
	    right: -48px;
	    top: -1px;
	}

	.col-md-12 > a.yellow-button {
	    margin-left: -45px;
			margin-top: 30px;
	}
}

.yellow-button-center {
		@extend .yellow-button;
		margin-left: -45px;
}

.yellow-button-full {
	@include generic-button();
	color: $brand-primary !important;
	border: 1px solid $brand-primary;
	width: 263px;
	text-align: center;

	background: linear-gradient(to right, $brand-primary 50%, transparent 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease !important;

	&:hover {
		background-position: left bottom;
		color: $white !important;
	}

	&:after {
		content: '';
		border: 1px solid $brand-primary;
		height: 45px;
		width: 45px;
		background: url('../images/yellow-arrow.png') no-repeat center center;
		position: absolute;
	    right: -48px;
	    top: -1px;
	}
}

.dark-button {
	@include generic-button();
	color: $titles-primary !important;
	border: 1px solid $titles-primary;

	background: linear-gradient(to right, $titles-primary 50%, transparent 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease !important;

	&:hover {
		background-position: left bottom;
		color: $white !important;
	}

	&:after {
		content: '';
		border: 1px solid $titles-primary;
		height: 45px;
		width: 45px;
		background: url('../images/dark-arrow.png') no-repeat center center;
		position: absolute;
	    right: -48px;
	    top: -1px;
	}
}

.dark-button-center{
		@extend .dark-button;
		margin-left: -45px;
}

.margin-top-25 .dark.button-center{
	margin-left:0px;
	margin-top:20px;
}

.scroll-down {
	width: 72px;
	height: 72px;
	border-radius: 50%;
	border: 1px solid $brand-primary;
	cursor: pointer;
	background: url('../images/scrolldown-d.png') no-repeat bottom center;
	transition: all .5s ease !important;

	&:hover {
		background-position: top center;
	}
}
