.lang-selector {
	padding: 0 10px;
	display: block;
	color: $white;
	text-transform: uppercase;
	cursor: pointer;
	position: relative;
	border: 1px solid $white;
	margin-left: 10px;
	border-radius: 5px;

	&:after {
		position: absolute;
		background: url('../images/lang-tr.png') no-repeat center center;
		width: 6px;
		height: 3px;
		right: 0;
		top: 9px;
		content: "";
	}

	.ls-menu {
		position: absolute;
		display: none;
		left: 0;
		width: 100%;
		z-index: 999999;
		padding-top: 3px;

		a {
			background: rgba(0, 0, 0, 0.8);
			display: block;
			text-decoration: none;
			text-align: center;
			border-bottom: 1px solid #666;
			padding: 5px 0;
			&:hover { background: $brand-primary; color: $white; }
		}
	}

	&:hover {
		.ls-menu {
			display: block;
		}
	}
}
