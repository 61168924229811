.header-common {
	background: url('../images/header-back.jpg') no-repeat center center rgba(0,0,0,0.5);
	background-size: cover;
	height: 75px;
	margin: 0 -15px;
	margin-top: 84px;

	@media (min-width: $screen-sm) {
	    height: 250px;
		margin-top: 0;
	}

	@media (min-width: $screen-md) {
	    height: 350px;
	}

	&.hc-mesgolive-variant {
		display: flex;
		align-items: flex-end;
		padding-bottom: 30px;
		justify-content: center;

		#breadcrumbs {
			margin-top: 10px;
			color: #000;
			text-align: center; 

			a {
				color: #fff
			}
		}
	}
}

.header-prodotto-mesgo {
    background-color: #333333;
    height: 170px;
    width: 100vw;
    margin-left: -15px;

	@media (max-width: $screen-sm) {
	    height: 120px;
	}
}
