h2 {
	@include primary-type(20px);
	font-weight: 900;
	color: $titles-primary;
	padding-bottom: 15px;
    text-align: center;
    text-transform: uppercase;
	margin-bottom: 40px;
	background: url('../images/titles-under.png') no-repeat center bottom;
}

h1 {
	@include primary-type(42px);
	font-weight: 900;
	color: $titles-primary;
	text-align: center;
	margin-bottom: 30px;
	margin-top: 0;
	padding-bottom: 30px;
	background: url('../images/titles-under.png') no-repeat center bottom;

	@media (min-width: $screen-sm) {
	    @include primary-type(54px);
	}

	@media (min-width: $screen-md) {
	    @include primary-type(60px);
	}
}
