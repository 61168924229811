/* HOMEPAGE SLIDER REVOLUTION HACK */
.slotholder { transform: translate3d(0px, 0px, 0px) !important;}
/* HOMEPAGE SLIDER REVOLUTION HACK */

.upper-menu-container {
	border-bottom: 1px solid $white;
	text-align: right;
	padding: 10px 60px 10px 60px;
	max-height: 400px;
	display: none;
	//transition: max-height 0.15s ease-out;

	& .upper-menu > div  { display: inline-block; }

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			display: inline-block;
			margin-left: 15px;

			&.current-menu-item, &.current_page_parent {
				a {
					color: $brand-primary !important;
				}
			}
		}
	}

	a {
		@include primary-type(16px);
		color: $white !important;
		text-decoration: none !important;
	}

	@media (min-width: $screen-sm) {
	    display: block;
	}
}

.header-menu-container {
	padding: 25px 50px 0 60px;
	min-height: 84px;
}


@media(max-width: $screen-sm){
	nav.navbar-collapse.collapse.in li {
    padding: 4px 0;
	}
}

#lang_sel{
	@media(min-width: $screen-sm){
			display: none;
		}
}
/*
ul#menu-mesgo-main li:last-child ul li a{
	color: #fff;
}

ul#menu-mesgo-main-inglese li:last-child ul li a {
    color: #fff;
}
*/

.upper-menu-container div > ul.upper-nav > li.current_page_parent > a:visited {
    color: #fff !important;
}

.navbar-brand {
	height: auto;
	padding: 0;
	margin-left: 0;

	.masco-logo {
		display: inline-block;
		width: 204px;
		height: 64px;
		background: url('../images/mesgo-logo.png') no-repeat center center;
	}
}

.navbar-default {
	background-color: transparent;
    border: 0;
}

.navbar-static-top {
	position: absolute;
	width: 100%;
}

.navbar-collapse div {
	float: right;
}

.header-menu-container .navbar-collapse .menu-mesgo-main-inglese-container {
	@media(max-width: $screen-sm){
		float: left;
	}
    float: right;
}

.navbar-default {
	ul.navbar-nav {
		& > li {
			& > a {
				@include primary-type(12px);
				padding: 0;
				background-color: transparent !important;
				color: $white !important;
				text-transform: uppercase;
				font-weight: 900;
				padding-bottom: 2px;
				margin: 0 5px;
				border-bottom: 1px solid transparent;

				.caret { display: none; }

				@media (min-width: $screen-lg) {
					@include primary-type(16px);
					margin: 0 10px;
				}
			}

			ul.dropdown-menu {
				background: transparent;
				box-shadow: 0;
				border-radius: 0;
				padding: 20px 0 0;
				border: 0;

				li {
					border-bottom: 1px solid $menu-border;
					background: rgba(0, 0, 0, 0.8);

					& > a {
						@include primary-type(16px);
						background-color: transparent !important;
						color: $white !important;
						font-weight: 900;
						padding: 10px 10px;
						min-width: 250px;
					}
				}

				li:last-of-type { border-bottom: 0; }

				li.active, li:hover {
					background-color: $brand-primary;
				}
			}
		}

		& > li.active, & > li:hover, & > li.current-menu-parent {
			& > a {
				border-bottom: 1px solid $brand-primary;
			}
		}
	}
}

.mesgo-main {
	padding-top: 20px;
}

.affix {
	position: fixed;
	background: rgba(51, 51, 51, 0.9);

	.upper-menu-container {
		display: none;
		overflow: hidden;


	}

	.header-menu-container {
		padding: 15px 15px 0px 25px;

		@media(min-width: $screen-lg) {
			padding: 15px 50px 15px 60px;
		}

	}
}

@media (max-width: $screen-md) {
    .navbar-header {
        float: none;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin: 7.5px -15px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .navbar-text {
        float: none;
        margin: 15px 0;
    }
    /* since 3.1.0 */
    .navbar-collapse.collapse.in {
        display: block!important;
    }
    .collapsing {
        overflow: hidden!important;
    }
}
