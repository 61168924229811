.home-application-fields {
	background: url('../images/application-f-bg.jpg') no-repeat center center;
	background-size: cover;
	text-align: center;

	h2 { color: $white; }



	.home-application-fields.padded-section > div:last-child {
	    margin-left: -45px;
	}

	.haf-item {
		width: 158px;
		height: 158px;
		border: 1px solid $brand-primary;
		border-radius: 50%;
		display: inline-block;
		margin: 0 15px;
		overflow: hidden;
		position: relative;
		margin-bottom: 10px;

		.haf-primary {
			@include primary-type(13px);
			color: $brand-primary;
			text-transform: uppercase;
			font-weight: 900;
			margin-top: 105px;
		}

		.haf-secondary {
			width: 100%;
			height: 100%;
			padding-top: 19px;
			position: absolute;
			top: 0;
			opacity: 0;
			background-color: $brand-primary;

			a {
				@include primary-type(13px);
				text-transform: uppercase;
				text-decoration: none;


				color: $white !important;
				font-weight: 900;


				span {
					@include secondary-type(13px);
					font-size: 60px;
					font-weight: 400;
					display: block;
				}
			}
		}

		&.haf-automotive {
			background: url('../images/icon-automotive.png') no-repeat center 25px;
		}

		&.haf-transports {
			background: url('../images/icon-airplane.png') no-repeat center 35px;
		}

		&.haf-industry {
			background: url('../images/icon-industry.png') no-repeat center 35px;
		}

		&.haf-consumer {
			background: url('../images/icon-consumer.png') no-repeat center 50px;
		}
	}

}
