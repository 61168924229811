.divisions-carousel {
	margin-bottom: 50px;
	/*background: $brand-primary;*/
}

.divisions-container { margin-bottom: 30px; overflow: auto; }

.plant-badge {
	background: url('') no-repeat center center;
	background-size: cover;
	min-height: 400px;
	margin: 0 0 85px;
	position: relative;
	padding: 25px;
	margin: 0 -15px 120px;

	/*h3 {
		margin-top: 50%;
		transform: translateY(-50%);

		a {
			@include primary-type(16px);
			border: 1px solid $white;
			color: $white;
			display: block;
			max-width: 240px;
			text-transform: uppercase;
			margin: 0 auto;
			position: relative;
			padding: 10px;
			text-align: center;
			text-decoration: none;
		}
	}*/

	.pb-title {
		background: $brand-primary;
		max-width: 100%;
		width: 310px;
		position: absolute;
		bottom: -35px;
		padding: 25px;

		h3 a {
			@include primary-type(20px);
			color: $titles-primary !important;
			font-weight: 900;
			text-decoration: none !important;
			margin: 0;
		}

		.pb-details {
			display: none;
			@include primary-type(14px);
			color: $titles-primary;
			font-weight: 700;
		}
	}

	.pb-link {
		bottom: -85px;
    	position: absolute;
	}
}

.owl-wrapper {

}

.owl-wrapper {
    margin: 0 auto;
}

.owl-buttons {
	text-align: center;

	.owl-prev, .owl-next {
		display: inline-block;
		margin: 10px 10px 20px;
	}

	.arrow {
    	position: relative !important;
	}
}

.owl-pagination {
	position: absolute;
	width: 100%;
	bottom: 50px;
	text-align: right;
	padding-right: 15px;
}

.owl-page {
	display: inline-block;
	padding: 5px;

	span {
		background-color: $white !important;
		//border: 1px solid $brand-tertiary;
		width: 18px;
		display: inline-block;
		width: 12px;
		height: 12px;
		border-radius: 50%;
	}

	&.active span {
		//background-color: $brand-tertiary !important;
	}
}

.carousel-padding {
	@media ( min-width: $screen-sm-min ) {
		padding-right: 0 !important;
	}
}
