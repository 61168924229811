@mixin contacts-box {
	@include secondary-type(16px);
	text-align: center;
	background: url('../images/yellow-x.png') no-repeat center 90px;
	min-height: 485px;
	padding-top: 140px;

	strong {
		@include primary-type(16px);
		font-weight: 900;
	}
}

.contacts-dark {
	@include contacts-box();
	background-color: $contacts-dark;

	p {
		color: $white;
	}
}

.contacts-light {
	@include contacts-box();
	background-color: $contacts-light;

	p {
		color: $titles-primary;
	}
}
